import React, { Component } from "react";
import CreateGame from "./CreateGame";
import Game from "./Game";

class MainPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      play: false,
      id: null,
      master: null,
    };
  }

  handleStartGame = (id, master) => {
    const play = true;
    this.setState({ play, id: id, master: master });
  };

  render() {
    return (
      <div>
        {!this.state.play ? (
          <CreateGame
            onStartGame={(id, master) => this.handleStartGame(id, master)}
          />
        ) : (
          <Game id={this.state.id} master={this.state.master} />
        )}
      </div>
    );
  }
}

export default MainPage;
