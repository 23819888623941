import React, { Component } from "react";

class Game extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screenState: "section-event",
      name: "",
      nameChosen: false,
      valid: true,
      gameData: null,
      me: "",
      players: [],
    };
  }
  shot = new Audio("/shot.mp3");
  ready = new Audio("/ready.mp3");
  aim = new Audio("/aim.mp3");
  firegun = new Audio("/firegun.mp3");
  fire = new Audio("/fire.mp3");
  dead = false;

  checkIfMounted() {
    return this.mounted;
  }

  async componentDidMount() {
    this.mounted = true;
  }

  connect(id, user) {
    this.ws = new WebSocket(
      "wss://fucilato.soolutions.net/api/games/" +
        id +
        "?name=" +
        this.state.name +
        "&id=" +
        (user ? user : "_")
    );
    this.ws.onopen = () => {
      console.log("wss connected!");
    };

    this.ws.onmessage = (e) => {
      let body = {};
      try {
        body = JSON.parse(e.data);
        console.log(body);
      } catch (e) {
        console.log(e.message);
      }

      if (!body) return console.log("Empty JSON!!!");

      if (body.shape === "fucilato.v1.newconnection") {
        this.setState({ gameData: body });
      }

      if (body.shape === "fucilato.v1.participants") {
        this.setState({ players: body.payload.players, me: body.payload.you });
      }

      if (body.shape === "fucilato.v1.gamestart") {
        if (body.payload.countdown === 2) this.handleSound("ready");
        if (body.payload.countdown === 1) this.handleSound("aim");
        if (body.payload.countdown === 0) {
          if (body.payload.firedYou) this.handleSound("firegun");
          else this.handleSound("fire");
        }

        if (body.payload.firedYou) {
          console.log("bianco");
          this.setState({ screenState: "white section-event" });
          setTimeout(() => {
            this.setState({ screenState: "section-event" });
          }, 2000);
        }
      }

      console.log(this.state);
    };

    this.ws.onclose = (e) => {
      if (e.code === 1008) {
        console.log("Il server mi ha buttato fuori");
        return;
      }
      if (!this.checkIfMounted()) return this.componentWillUnmount();
      console.log(
        "Socket is closed. Reconnect will be attempted in 1 second.",
        e
      );
      setTimeout(() => {
        this.connect(id, this.state.gameData.id);
      }, 1000);
    };

    this.ws.onerror = (err) => {
      console.error(
        "Socket encountered error: ",
        err.message,
        "Closing socket"
      );
    };
  }

  componentWillUnmount() {
    this.mounted = false;
    this.ws.onclose = (e) => {
      console.log("Socket is safely closed.", e.reason);
    };
    this.ws.close();
  }

  readyToPlay = (state) => {
    if (!this.dead) {
      if (state) {
        this.setState({ screenState: "green section-event" });
        this.ws.send(
          JSON.stringify({
            shape: "fucilato.v1.action",
            payload: {
              actionType: "pressed",
            },
          })
        );
      } else {
        this.setState({ screenState: "section-event" });
        this.ws.send(
          JSON.stringify({
            shape: "fucilato.v1.action",
            payload: {
              actionType: "released",
            },
          })
        );
      }
    }
  };

  handeNameChosen = () => {
    if (this.state.name.length === 0) {
      this.setState({ valid: false });
    } else {
      this.connect(this.props.id, this.props.master);
      this.setState({ nameChosen: true, valid: true });
    }
  };

  handleDeletePlayer = (user) => {
    console.log(user);
  };

  handleSound = (sound) => {
    if (sound === "shot") this.shot.play();
    if (sound === "ready") this.ready.play();
    if (sound === "aim") this.aim.play();
    if (sound === "firegun") this.firegun.play();
    if (sound === "fire") this.fire.play();
  };

  getPlayerbadge = (p) => {
    let response = "green";
    if (!p.status) response = "red";
    if (!p.active) response = "grey";
    if (p.id === this.state.me && p.status) {
      this.dead = false;
      response = "yellow";
    }
    if (p.id === this.state.me && !p.status) {
      this.dead = true;
      response = "red";
    }
    return response + " rounded button-text";
  };

  render() {
    return (
      <>
        {!this.state.nameChosen ? (
          <div className="mt-5">
            <div className="form-group">
              {this.state.valid ? (
                <h4 className="text">
                  Allora, come ti chiami brutto ubriacone?
                </h4>
              ) : (
                <h4 className="text">
                  Sei così ubriaco da non riuscire a scrivere il tuo nome?
                </h4>
              )}

              <input
                type="text"
                className="form-control mt-4"
                id="nameImput"
                placeholder="Scegli un nome"
                value={this.state.name}
                onChange={({ currentTarget: input }) =>
                  this.setState({ name: input.value })
                }
              />
            </div>
            <button
              onClick={() => {
                this.handeNameChosen();
                this.handleSound("shot");
              }}
              className="btn btn-warning mt-3 button-text"
            >
              Sparami
            </button>
          </div>
        ) : (
          <>
            {this.state.screenState === "white section-event" ? (
              <div className={this.state.screenState}></div>
            ) : (
              <div
                onMouseDown={() => this.readyToPlay(true)}
                onMouseUp={() => this.readyToPlay(false)}
                onTouchStart={() => this.readyToPlay(true)}
                onTouchEnd={() => this.readyToPlay(false)}
                className={this.state.screenState}
              >
                <h1 className="title mt-5 ">🪖 Mr Shot 🪖</h1>
                <div className="subtitle mt-4">
                  👆🏻 tieni premuto lo schermo 👆🏻
                </div>
                <div className="subtitle mt-1">per cominciare</div>
                <h4 className="text mt-5">
                  Partita {this.state.gameData?.game.name}
                </h4>
                <h4 className="text mt-5">
                  Gamecode: <span className="gamecode mt-5">{this.state.gameData?.game.id}
                  </span>
                </h4>
                <button type="button" className="btn btn-warning button-text mt-1 mb-1">
                    Rimuovi giocatori
                  </button>
                <div className="mt-5">
                  <div className="row justify-content-center">
                    {this.state.players?.map((p) => (
                      <div key={p.name} className="text col-4 p-1">
                        <div
                          onClick={() => {
                            this.handleDeletePlayer(p);
                          }}
                          className={this.getPlayerbadge(p)}
                        >
                          {p.status === 1 ? "" : "☠️"}
                          {p.name}
                          {p.status === 1 ? "" : "☠️"}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

export default Game;
