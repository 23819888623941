import React, { Component } from "react";
const baseUrl = "https://fucilato.soolutions.net/api";
const axios = require("axios");

class CreateGame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lobbyName: "",
      id: "",
    };
  }

  createNewLobby = async () => {
    try {
      const response = await axios.post(baseUrl + `/games`, {
        name: this.state.lobbyName,
      });
      this.props.onStartGame(response.data.id, response.data.master);
    } catch (error) {
      console.error(error);
    }
  };

  joinLobby = () => {
    this.props.onStartGame(this.state.id);
  };

  render() {
    return (
      <div>
        <h1 className="title mt-5">benvenuto</h1>
        <h4 className="subtitle">
          Questo è un drinking game basato sulla fortuna
        </h4>
        <div className="p-3">
          <h4 className="subtitle mt-4">Crea partita</h4>
          <div className="form-group">
            <h6 className="text">Sceli un nome per la partita</h6>

            <input
              type="text"
              className="form-control mt-2"
              id="nameImput"
              placeholder="Nome Partita"
              value={this.state.lobbyName}
              onChange={({ currentTarget: input }) =>
                this.setState({ lobbyName: input.value })
              }
            />
          </div>
          <button
            onClick={() => this.createNewLobby()}
            className="btn btn-warning mt-3 button-text"
          >
            Crea
          </button>
        </div>
        <div className="p-3">
          <h4 className="subtitle mt-4">Entra in una partita</h4>
          <div className="form-group">
            <input
              type="text"
              className="form-control mt-2"
              id="nameImput"
              placeholder="GAMECODE"
              value={this.state.id}
              onChange={({ currentTarget: input }) =>
                this.setState({ id: input.value.toUpperCase() })
              }
            />
          </div>
          <button
            onClick={() => this.joinLobby()}
            className="btn btn-warning mt-3 button-text"
          >
            Partecipa
          </button>
        </div>
      </div>
    );
  }
}

export default CreateGame;
