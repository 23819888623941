import "./App.css";
import MainPage from "./components/MainPage";

function App() {
  return (
    <div className="App noselect container">
      <MainPage></MainPage>
    </div>
  );
}

export default App;
